import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { Auth0Provider } from "@auth0/auth0-react";

import "./index.css";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import OneShot from "./pages/oneshot/OneShot";
import Chat from "./pages/chat/Chat";
import { AuthProvider } from "./AuthProvider";

initializeIcons();

export default function App() {
    return (
        <BrowserRouter>
            <Auth0Provider
                domain="redventures-prod.auth0.com"
                clientId="jgfx1uL9B7zaXeWjIy6Ka1qtZbOOglTt"
                authorizationParams={{ 
                    redirect_uri: window.location.origin,
                    cacheLocation:"localstorage",
                    useRefreshTokens: true,
                    audience: "production-hr-assistant-api-resource",
                    scope: "openid profile email read:all write:all"
                }}
            >
            <AuthProvider>
                <Routes>
                    <Route path="/" element={<Layout />}>
                        <Route index element={<Chat />} />
                        <Route path="qa" element={<OneShot />} />
                        <Route path="*" element={<NoPage />} />
                    </Route>
                </Routes>
                </AuthProvider>
            </Auth0Provider>
        </BrowserRouter>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
