import React from "react";
import { Stack, Text, IStackTokens} from "@fluentui/react";
import { SparkleFilled } from "@fluentui/react-icons";
import glyph from "../assets/logo-rv-glyph.png"
import styles from "./chat/Chat.module.css";

export const NoAuth = () => {

    const verticalGapStackTokens: IStackTokens = {
        childrenGap: 10,
        padding: 10,
    };

    return (
        <div className={`${styles.chatContainer} ${styles.chatRoot}`} style={{padding:"1em"}}>
            <img width="200px" src={glyph} className={styles.headerLogo} alt="logo" />
            <Stack horizontal>
                <Stack.Item align="stretch">
                    <h1 className={styles.chatEmptyStateTitle}>Ask HR</h1>
                </Stack.Item>
                <Stack.Item align="start">
                    <SparkleFilled fontSize={"70px"} primaryFill={"rgba(180, 180, 180, 1)"} aria-hidden="true" aria-label="Chat logo" />
                </Stack.Item>
            </Stack>
            <Stack tokens={verticalGapStackTokens} horizontalAlign="center">
                <Text>AskHR is currently only available for employees that work in North Carolina or South Carolina. </Text>
                <Text>We hope to expand to more areas soon!</Text>
                <Text>Please check out the HR Hub on Arvy for more information </Text>
            </Stack>
        </div>
    );
};