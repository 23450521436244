import { useState } from "react";
import { Outlet, NavLink, Link } from "react-router-dom";
import  Modal  from "react-modal";
import { IconButton } from '@fluentui/react/lib/Button';

import styles from "./Layout.module.css";
import logo from "../../assets/logo-redventures.png";

const Layout = () => {
    const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);

    function openModal() {
        setIsDisclaimerOpen(true);
    }

    function closeModal() {
        setIsDisclaimerOpen(false);
    }

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                <div className={styles.headerContainer}>
                    <Link to="/" className={styles.headerTitleContainer}>
                        <img src={logo} className={styles.headerLogo} alt="logo" />
                    </Link> 
                    <div className={styles.rightNavLinks}>
                        <button className={styles.modalButton} onClick={openModal}>Disclaimer</button>
                        <Link to="https://arvy.io/hr" className={styles.headerTitleContainer}>
                            <h4 className={styles.headerRightText}>Arvy HR Hub</h4>
                        </Link>
                    </div>
                    
                </div>
            </header>
            <Modal
                isOpen={isDisclaimerOpen}
                onRequestClose={closeModal}
                contentLabel="Disclaimer"
                className={styles.modal}
            >
                <div className={styles.modalHeader}>
                    <h4>Disclaimer</h4> 
                    <IconButton iconProps={{ iconName: 'Cancel' }} ariaLabel="Close popup modal" onClick={closeModal} />
                </div>
                <p>Please note that the AI chatbot provided for HR assistance is designed to offer general information on the current policies, procedures, and benefits of RED VENTURES, LLC and its affiliates (“Red Ventures”). Full details will be outlined in the Red Ventures’ Employee Handbook and Benefits guides. If there are any discrepancies between the AI chatbot and the official Red Ventures’ policy documents, the policy documents dictate the final policy. All policies are subject to change in the sole discretion of Red Ventures, subject to applicable law.</p>
                <p>While efforts have been made to ensure the accuracy and reliability of the information provided, it is important to understand that the chatbot cannot replace the official employee policy documents (i.e., Employee Handbooks, Benefits Guides), and expertise and professional judgment of human HR professionals at Red Ventures.</p>
                <p>The information provided by the AI chatbot, including the responses and suggestions, is based on its training data and may not encompass the entire spectrum of HR regulations, policies, and best practices. The information provided should not be considered as legal, financial, or professional advice specific to your situation. Please keep in mind that the AI chatbot is an automated system, and its responses are generated based on algorithms and data analysis. It may not have the ability to fully comprehend complex or nuanced questions. Accordingly, it is essential to consider specific circumstances and local regulations when addressing HR matters and recommended to consult with a HR professional if you encounter any issue or require further clarification on a matter or with any concern.</p>
                <p>By using the AI chatbot for HR assistance, you acknowledge and accept the limitations of its responses and agree to hold Red Ventures harmless from any liability arising from the use or reliance on the information provided by the Chatbot.</p>
                <p>Please use the AI chatbot responsibly and consider it as a supplementary tool to support your HR inquiries, but ultimately consult with HR for comprehensive and tailored advice.</p>
            </Modal>

            <Outlet />
        </div>
    );
};

export default Layout;
