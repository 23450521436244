import React, { PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Spinner } from '@fluentui/react';

import PropTypes from 'prop-types';



export const AuthProvider: React.FC<PropsWithChildren> = (props) => {
    const { error, isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
    
    if (isLoading) return <Spinner  />;

    if (!isAuthenticated && !error) {
            loginWithRedirect();
            return null;
    }

    return <>{props.children}</>;
};
