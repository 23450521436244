import React, { useMemo } from "react";
import { Stack, IconButton, Text } from "@fluentui/react";
import DOMPurify from "dompurify";
import { useSearchParams } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import styles from "./Answer.module.css";

import { AskResponse, getCitationFilePath, submitFeedbackApi } from "../../api";
import { parseAnswerToHtml } from "./AnswerParser";
import { AnswerIcon } from "./AnswerIcon";

interface Props {
    answer: AskResponse;
    isSelected?: boolean;
    onCitationClicked: (filePath: string) => void;
    onThoughtProcessClicked: () => void;
    onSupportingContentClicked: () => void;
    onFollowupQuestionClicked?: (question: string) => void;
    showFollowupQuestions?: boolean;
    correlationId?: string;
}

export const Answer = ({
    answer,
    isSelected,
    onCitationClicked,
    onThoughtProcessClicked,
    onSupportingContentClicked,
    onFollowupQuestionClicked,
    showFollowupQuestions,
    correlationId
}: Props) => {
    const parsedAnswer = useMemo(() => parseAnswerToHtml(answer.answer, onCitationClicked), [answer]);

    let sanitizedAnswerHtml = DOMPurify.sanitize(parsedAnswer.answerHtml);
    // Replace any URLs with links
    sanitizedAnswerHtml = sanitizedAnswerHtml.replace(/(https?:\/\/[^\s]+)/g, '<a href="$1">$1</a>');
    const [searchParams] = useSearchParams();
    const showDev = searchParams.get('showDev') === '1';
    const [feedbackSubmitted, setFeedbackSubmitted] = React.useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const handleFeedback = async (helpful: boolean) => {
        if(!correlationId) {
            console.log("Err: Cannot submit feedback without correlationId")
            return;
        }
        const options = {   
            correlationId: correlationId,
            helpful: helpful,
        };
        const token = await getAccessTokenSilently();
        const response = await submitFeedbackApi(options, token);
        if(!response.error) setFeedbackSubmitted(true);
    }
    
    return (
        <Stack className={`${styles.answerContainer} ${isSelected && styles.selected}`} verticalAlign="space-between">
            {showDev && <Stack.Item>
                <Stack horizontal horizontalAlign="space-between">
                    <AnswerIcon />
                    <div>
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "Lightbulb" }}
                            title="Show thought process"
                            ariaLabel="Show thought process"
                            onClick={() => onThoughtProcessClicked()}
                            disabled={!answer.thoughts}
                        />
                        <IconButton
                            style={{ color: "black" }}
                            iconProps={{ iconName: "ClipboardList" }}
                            title="Show supporting content"
                            ariaLabel="Show supporting content"
                            onClick={() => onSupportingContentClicked()}
                            disabled={!answer.data_points.length}
                        />
                    </div>
                </Stack>
            </Stack.Item>}

            <Stack.Item grow>
                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: sanitizedAnswerHtml }}></div>
            </Stack.Item>

            {!!parsedAnswer.citations.length && (
                <Stack.Item>
                    <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                        <span className={styles.citationLearnMore}>Citations:</span>
                        {parsedAnswer.citations.map((x, i) => {
                            const path = getCitationFilePath(x);
                            return (
                                <a key={i} className={styles.citation} title={x} onClick={() => onCitationClicked(path)}>
                                    {`${++i}. ${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}

            {!!parsedAnswer.followupQuestions.length && showFollowupQuestions && onFollowupQuestionClicked && (
                <Stack.Item>
                    <Stack horizontal wrap className={`${!!parsedAnswer.citations.length ? styles.followupQuestionsList : ""}`} tokens={{ childrenGap: 6 }}>
                        <span className={styles.followupQuestionLearnMore}>Follow-up questions:</span>
                        {parsedAnswer.followupQuestions.map((x, i) => {
                            return (
                                <a key={i} className={styles.followupQuestion} title={x} onClick={() => onFollowupQuestionClicked(x)}>
                                    {`${x}`}
                                </a>
                            );
                        })}
                    </Stack>
                </Stack.Item>
            )}
            
            <Stack.Item>
                <div className={`${styles.feedback}`}>
                    {(feedbackSubmitted) ? <Text>Thank you for your feedback! &hearts; </Text> :
                    (
                        <>
                            <Text>Help us improve! Was this answer helpful?</Text>
                            <IconButton
                                style={{ color: "black" }}
                                iconProps={{ iconName: "Emoji2" }}
                                title="Helpful?"
                                ariaLabel="This answer was helpful"
                                onClick={() => handleFeedback(true)}
                            />
                            <IconButton
                                style={{ color: "black" }}
                                iconProps={{ iconName: "EmojiNeutral" }}
                                title="This answer could be better"
                                ariaLabel="This answer could be better"
                                onClick={() => handleFeedback(false)}
                            />
                        </>
                    )}
                </div>
            </Stack.Item>
        </Stack>
    );
};
