import { Text } from "@fluentui/react";
import { Settings24Regular } from "@fluentui/react-icons";
import { useSearchParams } from "react-router-dom";


import styles from "./SettingsButton.module.css";

interface Props {
    className?: string;
    onClick: () => void;
}

export const SettingsButton = ({ className, onClick }: Props) => {
    const [searchParams] = useSearchParams();
    const showDev = searchParams.get('showDev');
    if(showDev !== "1") return null;

    return (
        <div className={`${styles.container} ${className ?? ""}`} onClick={onClick}>
            <Settings24Regular />
            <Text>{"Developer settings"}</Text>
        </div>
    );
};
